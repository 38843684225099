import {createSlice} from '@reduxjs/toolkit';

const initialState = {value: 1};

export const selectedSlice = createSlice({
  name: 'selected',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    clear: (state, action) => {
      state.value = initialState.value;
    },
  },
});

export const {set, clear} = selectedSlice.actions;

export const selectSelected = state => state.selected.value;

export default selectedSlice.reducer;
