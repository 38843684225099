const styles = {
  label: {
    fontFamily: 'Montserrat',
    paddingLeft: 12,
    borderRadius: 4,
    fontWeight: 600,
    color: '#000',
    fontSize: 18,
    alignSelf: 'flex-start',
  },
  labelWrapper: {
    justifyContent: 'center',
    display: 'flex',
    fontFamily: 'Montserrat',
    padding: 7,
    borderRadius: 4,
    fontWeight: 600,
    color: '#000',
    width: 80,
    fontSize: 18,
  },
  container: {
    width: 464,
    height: 367,
    maxWidth: 464,
    maxHeight: 367,
    backgroundColor: `rgba(255, 255, 255, 0.5)`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    borderColor: '#a9a9a9',
    borderBottomRightRadius: 70,
    borderTopLeftRadius: 70,
  },
  input: {
    height: 42,
    backgroundColor: '#fff',
    borderWidth: 0,
    color: '#000',
    outline: 'none',
    fontWeight: 'bold',
    width: 307,
    borderRadius: 70,
    paddingLeft: 15,
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: -1,
  },
  logo: {
    width: 337,
    height: 131,
    alignSelf: 'center',
    display: 'flex',
    marginLeft: 35,
    marginTop: 26,
  },
};

export default styles;
