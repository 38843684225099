import styles from './styles';
import Button from '../../../components/button';

const DownloadOptions = props => {
  return (
    <div style={{display: 'flex', alignSelf: 'center'}}>
      <Button style={styles.button} onClick={props.xlsx}>
        <span style={styles.label}>
          Excel
          <img src={'assets/images/download.png'} alt={''} style={styles.img} />
        </span>
      </Button>
      <Button
        style={{
          ...styles.button,
          marginLeft: 32,
          marginRight: 32,
        }}
        onClick={props.pdf}>
        <span style={styles.label}>
          PDF
          <img src={'assets/images/download.png'} alt={''} style={styles.img} />
        </span>
      </Button>
      <Button
        style={{
          ...styles.button,
          width: '67px',
        }}
        onClick={props.printer}>
        <img src={'assets/images/print.png'} alt={''} />
      </Button>
    </div>
  );
};

export default DownloadOptions;
