import {useState} from 'react';
import './styles.css';

const Button = props => {
  const [fade, setFade] = useState(false);
  return (
    <button
      {...props}
      onAnimationEnd={() => {
        setFade(false);
      }}
      className={`${fade ? 'fade' : ''} button ${props.className ?? ''}`}
      onClick={() => {
        setFade(true);

        if (typeof props.onClick === 'function') props.onClick();
      }}
    />
  );
};

export default Button;
