import {useDispatch, useSelector} from 'react-redux';
import styles from './styles';
import {clear, selectSelected, set} from '../selectedSlice';
import {set as setLogin, clear as clearLogin} from '../../login/loginSlice';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/button';

const Menu = () => {
  const selected = useSelector(selectSelected);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div style={styles.menu}>
      <img
        src={'assets/images/logo2.png'}
        alt={''}
        style={{
          width: 101,
          height: 80,
          marginTop: 20,
        }}
      />
      <Button
        onClick={() => dispatch(set(1))}
        style={{
          marginTop: 45,
          ...(selected === 1 ? styles.selectedButton : styles.button),
        }}>
        <span
          style={{
            ...(selected === 1
              ? styles.selectedButtonLabel
              : styles.buttonLabel),
          }}>
          Títulos em aberto
        </span>
      </Button>
      <Button
        onClick={() => dispatch(set(2))}
        style={{
          marginTop: 40,
          ...(selected === 2 ? styles.selectedButton : styles.button),
        }}>
        <span
          style={{
            ...(selected === 2
              ? styles.selectedButtonLabel
              : styles.buttonLabel),
          }}>
          Títulos Pagos
        </span>
      </Button>
      <Button
        style={styles.sairButton}
        onClick={() => {
          dispatch(setLogin(undefined));
          dispatch(clear());
          dispatch(clearLogin());
          navigate('/', {replace: true});
        }}>
        <span style={styles.buttonLabel}>Sair</span>
      </Button>
    </div>
  );
};

// atribuicao = nr_pedido
// n doc sap = solicitar ao jeferson
// n nota fisca = nr_nota
// data emissao = data_documento
// dt vencimento = data_vencimento
// valor = valor_liquido
// descricao = texto (solicitar ao jeferson )
// forma pagamento = solicitar ao jeferson
// unidade = solicitar ao jeferson = local de negócio
//
// atribuicao = nr_pedido
// n nota fisca = nr_nota
// data emissao = data_documento
// dt vencimento = data_vencimento
// dt pagamento = data_pagamento
// filial = solicitar ao jeferson
//
// n doc sap
// filial
// unidade = local de negócio
// descricao
// forma pagamento
export default Menu;
