import {createSlice} from '@reduxjs/toolkit';

const initialState = {};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    clear: (state, action) => {
      state.value = initialState;
    },
  },
});

export const {set, clear} = loginSlice.actions;

export const selectLogin = state => state.login.value;

export default loginSlice.reducer;
