const styles = {
  menu: {
    position: 'absolute',
    left: 0,
    width: '18%',
    height: '100%',
    background:
      'linear-gradient( #1B7754 -0.41%, #589A39 20.93%, #79AC2B 73.35%, #F7D21E 113.82%)',
    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 70px 0px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  selectedButton: {
    border: 0,
    width: '218px',
    height: '42px',
    background: '#5C6062',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '70px',
    color: '#fff',
  },
  selectedButtonLabel: {
    fontFamily: "'Montserrat'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  buttonLabel: {
    fontFamily: "'Montserrat'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    textAlign: 'center',
    color: '#000000',
  },
  button: {
    border: 0,
    width: '218px',
    height: '42px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '70px',
  },
  sairButton: {
    border: 0,
    position: 'absolute',
    width: '173px',
    height: '33px',
    bottom: 48,
    background: '#FFFFFF',
    borderRadius: '70px',
  },
};

export default styles;
