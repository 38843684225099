import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../features/login/loginSlice';
import selectedReducer from '../features/home/selectedSlice';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    login: loginReducer,
    selected: selectedReducer
  },
});
