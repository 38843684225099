export default {
  button: {
    width: '126px',
    height: '43px',
    background: '#F7D21E',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
  },
  label: {
    fontFamily: "'Montserrat'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#3F8B44',
    display: 'flex',
    alignItems: 'center',
  },
  img: {marginLeft: 10},
};
