import styled, {css} from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

const Overlay = ({loading, text}) => {
  return (
    <DarkBackground disappear={loading}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <LoadingOverlay
          active={true}
          styles={{
            spinner: base => ({
              ...base,
              '& svg circle': {
                // stroke: '#1B7754',
              },
            }),
          }}
          // spinner={<BounceLoader />}
          spinner={true}
          text={text}></LoadingOverlay>
      </div>
    </DarkBackground>
  );
};
export default Overlay;
