import React, {useEffect, useState} from 'react';
import utils from '../../../services/utils';
import './styles.css';
const Table = ({
  totalRow = true,
  group,
  sumColumn = [{column: 'valor_liquido', label: 'Valor Total'}],
  ...props
}) => {
  const [data, setData] = useState([...(props.data || [])]);
  const [header, setHeader] = useState([...(props.header || [])]);

  useEffect(() => {
    setData([...(props.data || [])]);
  }, [props.data]);

  useEffect(() => {
    setHeader([...(props.header || [])]);
  }, [props.header]);
  let groupValue;
  let total = {};
  for (let c of sumColumn) {
    total[c.column] = 0;
  }
  let printTotal = false;
  return (
    <div
      style={{
        background: '#FFFFFF',
        boxShadow: '4px 4px 40px rgba(0, 0, 0, 0.25)',
        borderRadius: '30px 0px',
        width: '90%',
        alignSelf: 'center',
        maxHeight: '60%',
        overflow: 'auto',
      }}>
      <table id="tableToFile" style={{display: 'none'}}>
        <thead>
          <tr>
            {header.map(h => (
              <th>{h.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const newGroupValue = item[group];
            let auxTotal = {...total};
            if (index === 1) {
              console.log('=>(index.js:51) totals', total);
            }
            if (!!groupValue && groupValue !== newGroupValue) {
              for (let c of sumColumn) {
                total[c.column] = Number(item[c.column]);
              }
              printTotal = true;
              groupValue = newGroupValue;
            } else {
              printTotal = false;
              for (let c of sumColumn) {
                total[c.column] += Number(item[c.column]);
              }
              groupValue = newGroupValue;
            }
            return (
              <>
                {printTotal && (
                  <tr>
                    <td colSpan={header.length}>
                      {sumColumn.map((v, k) => {
                        return (
                          <span style={{marginRight: 20}}>
                            {v.label}: {utils.toCurrency(auxTotal[v.column])}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                )}
                <tr>
                  {header.map((header, i) => {
                    return (
                      <td>
                        {header.prop === 'valor' ||
                        header.prop === 'valor_liquido' ||
                        header.prop === 'desconto'
                          ? utils.toCurrency(item[header.prop])
                          : item[header.prop]}
                      </td>
                    );
                  })}
                </tr>
                {index === data.length - 1 &&
                  totalRow &&
                  (() => {
                    groupValue = undefined;
                    auxTotal = {...total};
                    for (let c of sumColumn) {
                      total[c.column] = 0;
                    }
                    printTotal = false;
                    return (
                      <tr>
                        <td colSpan={header.length}>
                          {sumColumn.map((v, k) => {
                            return (
                              <span style={{marginRight: 20}}>
                                {v.label}:{utils.toCurrency(auxTotal[v.column])}
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })()}
              </>
            );
          })}
        </tbody>
      </table>
      <table style={{borderCollapse: 'separate', borderSpacing: 0}}>
        <thead>
          <tr>
            {header.map((h, index) => (
              <th
                style={{
                  border: 'solid',
                  borderTop: '0',
                  borderRight: '1px solid #E4E4E4',
                  borderLeft: `${index === 0 ? '0' : '1'}px solid #E4E4E4`,
                  borderBottom: '2px solid #E4E4E4',
                  // padding: 14,
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // display: 'flex',
                  width: h.width + 'px',
                  paddingBottom: 14,
                  paddingTop: 14,
                }}>
                <span
                  style={{
                    fontFamily: "'Montserrat'",
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 12,
                    color: '#000000',
                  }}>
                  {h.name}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const newGroupValue = item[group];

            let auxTotal = {...total};
            if (!!groupValue && groupValue !== newGroupValue) {
              for (const c of sumColumn) {
                total[c.column] = Number(item[c.column]);
              }
              printTotal = true;
              groupValue = newGroupValue;
            } else {
              printTotal = false;
              for (const c of sumColumn) {
                total[c.column] += Number(item[c.column]);
              }
              groupValue = newGroupValue;
            }
            return (
              <>
                {printTotal && totalRow && (
                  <tr>
                    <td
                      colSpan={header.length}
                      style={{
                        height: 25,
                        backgroundColor: '#A1A5A7',
                        textAlign: 'center',
                      }}>
                      {sumColumn.map((v, k) => {
                        return (
                          <span
                            style={{
                              fontFamily: "'Montserrat'",
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '10px',
                              marginRight: 20,
                            }}>
                            <span
                              style={{
                                fontFamily: "'Montserrat'",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}>
                              {`${v.label}:`}
                            </span>{' '}
                            <span style={{marginLeft: 0}}>
                              {utils.toCurrency(auxTotal[v.column])}
                            </span>
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                )}
                <tr>
                  {header.map((header, i) => {
                    return (
                      <td
                        style={{
                          border: 'solid',
                          borderTop: '0px',
                          borderRight: '1px solid #E4E4E4',
                          borderLeft: `${i === 0 ? '0' : '1'}px solid #E4E4E4`,
                          borderBottom: '2px solid #E4E4E4',
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: header.width + 'px',
                          paddingBottom: 4,
                          paddingTop: 4,
                        }}>
                        <span
                          style={{
                            fontFamily: "'Montserrat'",
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '10px',
                            color: '#000000',
                          }}>
                          {header.prop === 'valor' ||
                          header.prop === 'valor_liquido' ||
                          header.prop === 'desconto'
                            ? utils.toCurrency(item[header.prop])
                            : item[header.prop]}
                        </span>
                      </td>
                    );
                  })}
                </tr>
                {index === data.length - 1 &&
                  totalRow &&
                  (() => {
                    groupValue = undefined;
                    auxTotal = {...total};
                    for (let c of sumColumn) {
                      total[c.column] = 0;
                    }
                    printTotal = false;
                    return (
                      <tr>
                        <td
                          colSpan={header.length}
                          style={{
                            height: 25,
                            backgroundColor: '#A1A5A7',
                            textAlign: 'center',
                          }}>
                          {sumColumn.map((v, k) => {
                            return (
                              <span
                                style={{
                                  fontFamily: "'Montserrat'",
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  marginRight: 20,
                                }}>
                                <span
                                  style={{
                                    fontFamily: "'Montserrat'",
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                  }}>
                                  {`${v.label}:`}
                                </span>{' '}
                                <span style={{marginLeft: 0}}>
                                  {utils.toCurrency(auxTotal[v.column])}
                                </span>
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })()}
              </>
            );
          })}
        </tbody>
      </table>
      <div id={'table'} style={{display: 'none'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          {header.map((item, index) => {
            return (
              <div
                className={'table-header'}
                style={{
                  border: 'solid',
                  borderTop: '0',
                  borderRight: '1px solid #E4E4E4',
                  borderLeft: `${index === 0 ? '0' : '1'}px solid #E4E4E4`,
                  borderBottom: '2px solid #E4E4E4',
                  // padding: 14,
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  width: item.width + 'px',
                  paddingBottom: 14,
                  paddingTop: 14,
                }}>
                <span
                  style={{
                    fontFamily: "'Montserrat'",
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 12,
                    color: '#000000',
                  }}>
                  {item.name}
                </span>
              </div>
            );
          })}
        </div>

        {data.map((item, index) => {
          const newGroupValue = item[group];

          const auxTotal = {...total};
          if (!!groupValue && groupValue !== newGroupValue) {
            for (const c of sumColumn) {
              total[c.column] = Number(item[c.column]);
            }
            printTotal = true;
            groupValue = newGroupValue;
          } else {
            printTotal = false;
            for (const c of sumColumn) {
              total[c.column] += Number(item[c.column]);
            }
            groupValue = newGroupValue;
          }
          return (
            <>
              {printTotal && totalRow && (
                <div
                  style={{
                    width: '100%',
                    height: 25,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#A1A5A7',
                    alignItems: 'center',
                  }}>
                  {Object.keys(auxTotal).map((k, v) => {
                    return (
                      <span
                        style={{
                          fontFamily: "'Montserrat'",
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '10px',
                          marginRight: 20,
                        }}>
                        <span
                          style={{
                            fontFamily: "'Montserrat'",
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '12px',
                          }}>
                          {`${v.label}:`}
                        </span>{' '}
                        <span style={{marginLeft: 0}}>
                          {utils.toCurrency(auxTotal[v.column])}
                        </span>
                      </span>
                    );
                  })}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                {' '}
                {header.map((header, i) => {
                  return (
                    <div
                      style={{
                        border: 'solid',
                        borderTop: '0',
                        borderRight: '1px solid #E4E4E4',
                        borderLeft: `${i === 0 ? '0' : '1'}px solid #E4E4E4`,
                        borderBottom: '2px solid #E4E4E4',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        width: header.width + 'px',
                        paddingBottom: 4,
                        paddingTop: 4,
                      }}>
                      <span
                        style={{
                          fontFamily: "'Montserrat'",
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '10px',
                          color: '#000000',
                        }}>
                        {header.prop === 'valor' ||
                        header.prop === 'valor_liquido' ||
                        header.prop === 'desconto'
                          ? utils.toCurrency(item[header.prop])
                          : item[header.prop]}
                      </span>
                    </div>
                  );
                })}
              </div>
              {index === data.length - 1 && totalRow && (
                <div
                  style={{
                    width: '100%',
                    height: 25,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#A1A5A7',
                    alignItems: 'center',
                  }}>
                  {sumColumn.map((v, k) => {
                    return (
                      <span
                        style={{
                          fontFamily: "'Montserrat'",
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '10px',
                          marginRight: 20,
                        }}>
                        <span
                          style={{
                            fontFamily: "'Montserrat'",
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '12px',
                          }}>
                          {`${v.label}:`}
                        </span>{' '}
                        <span style={{marginLeft: 0}}>
                          {utils.toCurrency(total[v.column])}
                        </span>
                      </span>
                    );
                  })}
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
