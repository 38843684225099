import {DateRangePicker} from 'react-date-range';
import pt from 'date-fns/locale/pt';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import {useState} from 'react'; // theme css file
import {addDays} from 'date-fns';
import Button from '../../../components/button';

const Calendar = ({...props}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), -7),
      key: 'selection',
    },
  ]);

  let _isSameDay = _interopRequireDefault(require('date-fns/isSameDay'));

  let _endOfWeek = _interopRequireDefault(require('date-fns/endOfWeek'));

  let _startOfWeek = _interopRequireDefault(require('date-fns/startOfWeek'));

  let _addMonths = _interopRequireDefault(require('date-fns/addMonths'));

  let _endOfMonth = _interopRequireDefault(require('date-fns/endOfMonth'));

  let _startOfMonth = _interopRequireDefault(require('date-fns/startOfMonth'));

  let _startOfDay = _interopRequireDefault(require('date-fns/startOfDay'));

  let _endOfDay = _interopRequireDefault(require('date-fns/endOfDay'));

  let _addDays = _interopRequireDefault(require('date-fns/addDays'));

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {default: obj};
  }

  function ownKeys(object, enumerableOnly) {
    let keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      let symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) {
        symbols = symbols.filter(function (sym) {
          return Object.getOwnPropertyDescriptor(object, sym).enumerable;
        });
      }
      keys.push.apply(keys, symbols);
    }
    return keys;
  }

  function _objectSpread(target) {
    for (let i = 1; i < arguments.length; i++) {
      let source = arguments[i] != null ? arguments[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(
          target,
          Object.getOwnPropertyDescriptors(source),
        );
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(
            target,
            key,
            Object.getOwnPropertyDescriptor(source, key),
          );
        });
      }
    }
    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true,
      });
    } else {
      obj[key] = value;
    }
    return obj;
  }

  let defineds = {
    startOfWeek: (0, _startOfWeek.default)(new Date()),
    endOfWeek: (0, _endOfWeek.default)(new Date()),
    startOfLastWeek: (0, _startOfWeek.default)(
      (0, _addDays.default)(new Date(), -7),
    ),
    endOfLastWeek: (0, _endOfWeek.default)(
      (0, _addDays.default)(new Date(), -7),
    ),
    startOfToday: (0, _startOfDay.default)(new Date()),
    endOfToday: (0, _endOfDay.default)(new Date()),
    startOfYesterday: (0, _startOfDay.default)(
      (0, _addDays.default)(new Date(), -1),
    ),
    endOfYesterday: (0, _endOfDay.default)(
      (0, _addDays.default)(new Date(), -1),
    ),
    startOfMonth: (0, _startOfMonth.default)(new Date()),
    endOfMonth: (0, _endOfMonth.default)(new Date()),
    startOfLastMonth: (0, _startOfMonth.default)(
      (0, _addMonths.default)(new Date(), -1),
    ),
    endOfLastMonth: (0, _endOfMonth.default)(
      (0, _addMonths.default)(new Date(), -1),
    ),
  };
  let staticRangeHandler = {
    range: {},
    isSelected: function isSelected(range) {
      let definedRange = this.range();
      return (
        (0, _isSameDay.default)(range.startDate, definedRange.startDate) &&
        (0, _isSameDay.default)(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map(function (range) {
      return _objectSpread(_objectSpread({}, staticRangeHandler), range);
    });
  }

  let defaultStaticRanges = createStaticRanges([
    {
      label: 'Hoje',
      range: function range() {
        return {
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday,
        };
      },
    },
    {
      label: 'Ontem',
      range: function range() {
        return {
          startDate: defineds.startOfYesterday,
          endDate: defineds.endOfYesterday,
        };
      },
    },
    {
      label: 'Esta semana',
      range: function range() {
        return {
          startDate: defineds.startOfWeek,
          endDate: defineds.endOfWeek,
        };
      },
    },
    {
      label: 'Semana passada',
      range: function range() {
        return {
          startDate: defineds.startOfLastWeek,
          endDate: defineds.endOfLastWeek,
        };
      },
    },
    {
      label: 'Este mês',
      range: function range() {
        return {
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfMonth,
        };
      },
    },
    {
      label: 'Mês passado',
      range: function range() {
        return {
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth,
        };
      },
    },
  ]);
  if (props.show === false) {
    return <></>;
  }
  return (
    <div
      style={{
        border: 'solid #E4E4E4',
        borderRadius: 8,
        padding: 20,
        paddingBottom: 40,
        backgroundColor: '#fff',
        zIndex: 1,
        position: 'relative',
      }}>
      <DateRangePicker
        rangeColors={['#1B7754']}
        onChange={item => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        ranges={state}
        direction="horizontal"
        locale={pt}
        staticRanges={defaultStaticRanges}
        inputRanges={[]}
      />
      <Button
        style={{
          border: 0,
          position: 'absolute',
          bottom: 20,
          right: 20,
          borderRadius: 8,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 6,
          paddingBottom: 6,
          backgroundColor: '#1B7754',
        }}>
        <span
          style={{
            fontFamily: "'Montserrat'",
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            textAlign: 'center',
            color: '#FFFFFF',
          }}
          onClick={() => {
            props.onChange({
              initial: state[0].startDate,
              final: state[0].endDate,
            });
          }}>
          Buscar
        </span>
      </Button>
    </div>
  );
};
export default Calendar;
