import {useState} from 'react';
import styles from './styles';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import {useDispatch} from 'react-redux';
import {set} from './loginSlice';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {__DEV__, BASE_URL} from '../../config';
import Overlay from '../../components/overlay';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/button';

const Login = () => {
  const [cpfCnpj, setCpfCnpj] = useState(__DEV__ ? '10280806000134' : '');
  const [email, setEmail] = useState(__DEV__ ? 'bonetti@dimo.com.br' : '');
  const [token, setToken] = useState();
  const [validarToken, setValidarToken] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const login = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/autenticar`, {
        email,
        cnpj_cpf: cpfCnpj,
      });
      setValidarToken(true);
      setToken(__DEV__ ? response.data.token : undefined);
    } catch (e) {
      toast.error(e?.response?.data?.error);
    }
    setLoading(false);
  };

  const validarTokenReq = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/validar-token`, {
        email,
        token,
      });
      dispatch(set({cpfCnpj, email, token}));
      navigate('/home', {replace: true});
    } catch (e) {
      toast.error(e?.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} theme={'dark'} />
      <Overlay loading={loading} />
      <img
        src={'assets/images/login_background.jpeg'}
        alt={''}
        style={styles.backgroundImage}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <img src={'assets/images/logo1.png'} alt={''} style={styles.logo} />
      </div>
      <div style={styles.container}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 51,
            marginLeft: 78,
          }}>
          {!validarToken && (
            <>
              <label style={styles.label} htmlFor={'cpf_cnpj'}>
                CNPJ/CPF
              </label>
              <CpfCnpj
                id={'cpf_cnpj'}
                style={styles.input}
                value={cpfCnpj}
                onChange={e => {
                  const value = e.target.value;
                  setCpfCnpj(value);
                }}
              />
              <label style={{...styles.label, marginTop: 20}} htmlFor={'email'}>
                E-mail
              </label>
              <input
                style={styles.input}
                type="text"
                id={'email'}
                value={email}
                onChange={e => {
                  const value = e.target.value;
                  setEmail(value);
                }}
              />
            </>
          )}
          {validarToken && (
            <>
              <span
                style={{
                  paddingRight: 50,
                  marginBottom: 30,
                  fontFamily: "'Montserrat'",
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 18,
                }}>
                Você receberá em seu email ({email}), um token de acesso com
                validade de 10 minutos, digite-o abaixo para acessar
              </span>
              <input
                style={{...styles.input, textAlign: 'center'}}
                type="text"
                id={'token'}
                value={token}
                onChange={e => {
                  const value = e.target.value;
                  setToken(value);
                }}
              />
            </>
          )}
        </div>
        <Button
          style={{
            width: 135,
            height: 42,
            backgroundColor: '#1B7754',
            borderRadius: 70,
            color: '#fff',
            fontWeight: 400,
            fontSize: 18,
            position: 'absolute',
            bottom: 55,
            right: 79,
            boxShadow: '0px 4px 10px 0px #00000040',
            outline: 'none',
            border: 'none',
          }}
          onClick={validarToken ? validarTokenReq : login}>
          Entrar
        </Button>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 96,
          alignItems: 'center',
        }}>
        <div
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            width: '100%',
            height: '96px',
            top: 0,
            background:
              'linear-gradient(90deg, #1B7754 -0.41%, #589A39 45.64%, #79AC2B 92.08%, #F7D21E 113.82%)',
            borderTop: '1px solid #FFFFFF',
            boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: '0px',
          }}
        />
        <div>
          <img
            src={'assets/images/Rectangle25.png'}
            alt={''}
            style={{
              height: 96,
              maxHeight: 96,
              width: '60%',
              maxWidth: '60%',
              position: 'absolute',
              left: 0,
            }}
          />
          <div
            style={{
              width: '60%',
              position: 'absolute',
              height: '100%',
              bottom: 0,
              alignItems: 'center',
              display: 'flex',
            }}>
            <a href="https://www.fs.agr.br/" target="_blank">
              <img
                src={'assets/images/logo1.png'}
                alt={''}
                style={{
                  width: 194,
                  height: 75,
                  marginLeft: 38,
                }}
              />
            </a>
            <span
              style={{
                fontFamily: "'Montserrat'",
                fontStyle: 'normal',
                fontWeight: 500,
                color: '#5C6062',
                fontSize: 17,
                width: 158,
                height: 42,
                marginLeft: 53,
              }}>
              Siga nossas Redes Sociais
            </span>
            <a
              href="https://www.facebook.com/fsfuelingsustainability/"
              target="_blank">
              <img
                src={'assets/images/facebook.png'}
                alt={''}
                style={{marginLeft: 77}}
              />
            </a>
            <a
              href="https://br.linkedin.com/company/fsfuelingsustainability"
              target="_blank">
              <img
                src={'assets/images/linkedin.png'}
                alt={''}
                style={{marginLeft: 34}}
              />
            </a>
            <a
              href="https://www.instagram.com/fsfuelingsustainability/"
              target="_blank">
              <img
                src={'assets/images/instagram.png'}
                alt={''}
                style={{marginLeft: 34}}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCp6VG_HQNAgS90nD9gEyFgw/featured/"
              target="_blank">
              <img
                src={'assets/images/youtube.png'}
                alt={''}
                style={{marginLeft: 34}}
              />
            </a>
          </div>
        </div>
        <span
          style={{
            height: '21px',
            position: 'absolute',
            left: '60%',
            top: 39,
            fontFamily: "'Montserrat'",
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#FFFFFF',
          }}>
          ENERGIA QUE ABASTECE O BEM.
        </span>
      </div>
    </div>
  );
};

export default Login;
