import {Routes, Route, BrowserRouter} from 'react-router-dom';

import Login from './login';
import Home from './home';

const Router = () => {
  return (
    <div style={{flex: 1}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Router;
