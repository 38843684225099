import {useCallback, useEffect, useState} from 'react';
import styles from './styles';
import {useSelector} from 'react-redux';
import {selectSelected} from './selectedSlice';
import Menu from './menu';
import Table from './table';
import Calendar from './calendar';
import {addDays, format} from 'date-fns';
import axios from 'axios';
import DownloadOptions from './download-options';
import {selectLogin} from '../login/loginSlice';
import {useNavigate} from 'react-router-dom';
import {BASE_URL} from '../../config';
import Overlay from '../../components/overlay';
import {utils, writeFile} from 'xlsx';
import html2pdf from 'html-to-pdf-js';
import Button from '../../components/button';

const Home = () => {
  const selected = useSelector(selectSelected);
  const user = useSelector(selectLogin);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!user) {
      navigate('/', {replace: true});
    }
  }, []);
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [dates, setDates] = useState({
    initial: addDays(new Date(), -7),
    final: new Date(),
  });
  const ABERTOS = 1;
  const PAGOS = 2;
  const [showCalendar, setShowCalendar] = useState(false);
  const titulo =
    selected === ABERTOS
      ? 'Títulos em Aberto'
      : selected === PAGOS
      ? 'Títulos Pagos'
      : '';

  useEffect(() => {
    if (selected) getData();
  }, [selected, dates]);

  const getHeader = () => {
    if (selected === PAGOS) {
      return [
        {
          name: 'Atribuição',
          prop: 'nr_pedido',
          width: '99',
        },
        {
          name: 'Nº Doc. SAP',
          prop: 'nr_documento',
          width: '103',
        },
        {
          name: 'Nº Nota Fiscal',
          prop: 'nr_nota',
          width: '119',
        },
        {
          name: 'Data de emissão',
          prop: 'data_documento',
          width: '125',
        },
        {
          name: 'Data de vencimento',
          prop: 'data_vencimento',
          width: '138',
        },
        {
          name: 'Data de Pagamento',
          prop: 'data_pagamento',
          width: '138',
        },
        {
          name: 'Valor',
          prop: 'valor_liquido',
          width: '97',
        },
        {
          name: 'Desconto',
          prop: 'desconto',
          width: '97',
        },
        {
          name: 'Texto',
          prop: 'texto_item',
          width: '308',
        },
        {
          name: 'Frm. Pgto',
          prop: 'forma_pagamento',
          width: '85',
        },
        {
          name: 'Filial',
          prop: 'local_negocio',
          width: '53',
        },
        {
          name: 'Banco',
          prop: 'banco',
          width: '65',
        },
        {
          name: 'Agência',
          prop: 'agencia',
          width: '68',
        },
        {
          name: 'Conta',
          prop: 'conta',
          width: '61',
        },
        {
          name: 'Doc. Compensação',
          prop: 'doc_compensacao',
          width: '140',
        },
      ];
    } else if (selected === ABERTOS) {
      return [
        {
          name: 'Atribuição',
          prop: 'nr_pedido',
          width: '99',
        },
        {
          name: 'Nº Doc. SAP',
          prop: 'nr_documento',
          width: '103',
        },
        {
          name: 'Nº Nota Fiscal',
          prop: 'nr_nota',
          width: '119',
        },
        {
          name: 'Data de emissão',
          prop: 'data_documento',
          width: '125',
        },
        {
          name: 'Data de vencimento',
          prop: 'data_vencimento',
          width: '154',
        },
        {
          name: 'Valor',
          prop: 'valor_liquido',
          width: '87',
        },
        {
          name: 'Desconto',
          prop: 'desconto',
          width: '87',
        },
        {
          name: 'Descrição',
          prop: 'texto_item',
          width: '300',
        },
        {
          name: 'Forma de Pagamento',
          prop: 'forma_pagamento',
          width: '145',
        },
        {
          name: 'Filial',
          prop: 'local_negocio',
          width: '71',
        },
      ];
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const params = {
        email: user.email,
        token: user.token,
      };
      if (selected === PAGOS) {
        params.tipo_documento = 'P';
        params.data_inicio = format(dates.initial, 'yyyyMMdd');
        params.data_fim = format(dates.final, 'yyyyMMdd');
      } else {
        params.tipo_documento = 'A';
        params.data_inicio = '20190101';
        params.data_fim = format(new Date(), 'yyyyMMdd');
      }
      const response = await axios.get(`${BASE_URL}/titulos`, {params});
      setData(response.data);
      setHeader(getHeader());
    } catch (e) {}
    setLoading(false);
  };

  const printDocument = async () => {
    setLoading(true);
    const element = getTableToPrint();
    const opt = {
      margin: 1,
      filename: `Títulos ${selected === ABERTOS ? 'em aberto' : 'pagos'} .pdf`,
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 2},
      jsPDF: {unit: 'in', format: 'a3', orientation: 'landscape'},
    };
    // html2pdf().set(opt).from(element).save();

    html2pdf(element, opt);
    setLoading(false);
  };

  const getTableToPrint = () => {
    const element = document.getElementById('table').cloneNode(true);
    element.style.display = 'block';
    element.innerHTML =
      `<img src='assets/images/logo1.png' style="width: 200px; height: 90px"/>
<br/>
<br/>
<br/>
<br/>
` +
      `<span style="font-family: 'Montserrat';font-style: 'normal';font-weight: 800;font-size:30px;color: #1B7754;margin-left: 10">Títulos ${
        selected === ABERTOS ? 'em aberto' : 'pagos'
      }</span>
` +
      (selected === PAGOS
        ? `<br/><br/><span style="font-family: 'Montserrat';font-style: 'normal';font-weight: 800;font-size:17px;color: #1B7754;margin-left: 12">Período: ${format(
            dates.initial,
            'dd/MM/yyyy',
          )} - ${format(dates.final, 'dd/MM/yyyy')}</span> `
        : '') +
      (selected === PAGOS && notaFiscal != 'Todas'
        ? `<br/><span style="font-family: 'Montserrat';font-style: 'normal';font-weight: 800;font-size:17px;color: #1B7754;margin-left: 12">Nº Nota fiscal: ${notaFiscal}</span> `
        : '') +
      '<br/><br/><br/><div class="table">' +
      element.innerHTML +
      '</div>' +
      '<style>.table{border: 1px solid #E4E4E4}</style>';

    return element;
  };

  const printer = async () => {
    setLoading(true);
    const element = getTableToPrint();
    // const q = window.open();
    // q.document.write(element.innerHTML);
    // return;
    const opt = {
      margin: 1,
      filename: `Títulos ${selected === ABERTOS ? 'em aberto' : 'pagos'} .pdf`,
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 2},
      jsPDF: {unit: 'in', format: 'a3', orientation: 'landscape'},
    };
    html2pdf()
      .set(opt)
      .from(element)
      .outputPdf('blob')
      .then(v => {
        let pdfUrl = URL.createObjectURL(v);
        // // console.log('=>(index.js:216) pdfUrl', pdfUrl);
        const pdfWindow = window.open(pdfUrl);
        // //
        // // // Open the window
        // // Call print on it
        pdfWindow.print();
        setLoading(false);
      });

    // html2pdf(element, opt);
  };

  const xport = () => {
    setLoading(true);
    const table = document.getElementById('tableToFile');
    const wb = utils.table_to_book(table, {raw: true});

    writeFile(
      wb,
      `Títulos ${selected === ABERTOS ? 'em aberto' : 'pagos'} .xlsx`,
    );
    setLoading(false);
  };

  const [notaFiscal, setNotaFisca] = useState('Todas');

  const notasFiscais = [];

  data.map(v => {
    if (!notasFiscais.includes(v.nr_nota) && !!v.nr_nota)
      notasFiscais.push(v.nr_nota);
  });

  return (
    <div style={{flex: 1}}>
      <Menu />
      <div style={styles.container} id={'teste'}>
        <Overlay loading={loading} text={'Carregando'} />
        <div style={{height: 83}} />
        <span style={styles.titulo}>{titulo}</span>
        {selected === 2 && (
          <div style={{position: 'absolute', top: 83, left: 350}}>
            {!showCalendar && (
              <Button
                style={{
                  width: '300px',
                  height: '49.42px',
                  background: '#FFFFFF',
                  border: '1.5px solid #A1A5A7',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                  borderRadius: '16px',
                }}
                onClick={() => setShowCalendar(true)}>
                <span
                  style={{
                    position: 'absolute',
                    width: '45px',
                    height: '18px',
                    top: -10,
                    left: 13,
                    fontFamily: "'Poppins'",
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '12px',
                    lineHeight: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#A1A5A7',
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    paddingLeft: 10,
                  }}>
                  Período
                </span>
                <img
                  src={'assets/images/calendar.png'}
                  alt={''}
                  style={{
                    position: 'absolute',
                    top: 13,
                    left: 255,
                    width: 23,
                    height: 23,
                  }}
                />
                <span
                  style={{
                    position: 'absolute',
                    left: 30,
                    top: 15,
                    fontFamily: "'Montserrat'",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '17px',
                    color: '#A1A5A7',
                  }}>
                  {format(dates.initial, 'dd/MM/yyyy')} -{' '}
                  {format(dates.final, 'dd/MM/yyyy')}
                </span>
              </Button>
            )}
            {!showCalendar && (
              <>
                <select
                  style={{
                    width: '200px',
                    height: '49.42px',
                    background: '#FFFFFF',
                    border: '1.5px solid #A1A5A7',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                    borderRadius: '16px',
                    marginLeft: 10,
                    padding: 10,
                    fontFamily: "'Montserrat'",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '17px',
                    color: '#A1A5A7',
                    position: 'absolute',
                  }}
                  onChange={e => {
                    setNotaFisca(e.target.value);
                  }}
                  value={notaFiscal}
                  name="select">
                  <option value={'Todas'}>Todas</option>
                  {notasFiscais.map(v => {
                    return <option value={v}>{v}</option>;
                  })}
                </select>
                <span
                  style={{
                    position: 'absolute',
                    width: '63px',
                    height: '18px',
                    top: -10,
                    left: 323,
                    fontFamily: "'Poppins'",
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '12px',
                    lineHeight: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#A1A5A7',
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    paddingLeft: 10,
                  }}>
                  Nota Fiscal
                </span>
              </>
            )}
            <Calendar
              show={showCalendar}
              onChange={dates => {
                setDates(dates);
                setShowCalendar(false);
              }}
            />
          </div>
        )}
        {selected && (
          <>
            <div style={{height: 40}} />
            {data?.length > 0 && (
              <Table
                data={data.filter(v => {
                  if (notaFiscal === 'Todas') return v;
                  return v.nr_nota == notaFiscal;
                })}
                sumColumn={[
                  {column: 'valor_liquido', label: 'Valor Total'},
                  {column: 'desconto', label: 'Desconto Total'},
                ]}
                header={header}
                group={selected === PAGOS ? 'doc_compensacao' : undefined}
              />
            )}
            {data?.length == 0 && (
              <span
                style={{
                  alignSelf: 'center',
                  fontFamily: "'Montserrat'",
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 18,
                  color: '#000000',
                }}>
                Nenhum registro encontrado
              </span>
            )}
            <div style={{height: 40}} />
            {data.length > 0 && (
              <DownloadOptions
                pdf={printDocument}
                xlsx={xport}
                printer={printer}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
