const styles = {
  container: {
    width: '82%',
    height: '100%',
    position: 'absolute',
    left: '18%',
    display: 'flex',
    flexDirection: 'column',
  },
  titulo: {
    fontFamily: "'Montserrat'",
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '30px',
    color: '#1B7754',
    marginLeft: 53,
  },
};

export default styles;
